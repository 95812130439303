import React, { useEffect, useState } from "react";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";

import logo from "../images/hse-logo.png";
import workplaceEmail from "../data/workplace.json";

const defaultTheme = createTheme();

export default function EndQuiz() {
  const [isLoading, setIsLoading] = useState(false);
  const [questionsData, setQuestionsData] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [userAnswersData, setUserAnswersData] = useState(null);
  const [resultsSent, setResultsSent] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get("/questions/questions_ru.json")
      .then((res) => {
        setQuestionsData(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    const loadedAnswers = localStorage.getItem("userAnswers")
      ? JSON.parse(localStorage.getItem("userAnswers"))
      : {};
    setUserAnswersData(loadedAnswers);
  }, []);

  useEffect(() => {
    if (!questionsData.length || !userAnswersData) {
      return;
    }

    let correct = 0;
    let incorrect = 0;

    // Iterate over each question
    for (let question of questionsData) {
      const questionId = question.questionId.toString();
      const userAnswerIds = userAnswersData[questionId];

      const correctAnswerIds = question.answers
        .filter((answer) => answer.isCorrect)
        .map((answer) => answer.answerId);

      let isCorrect = false;

      // If the question was not answered, treat it as incorrect
      if (userAnswerIds) {
        isCorrect =
          JSON.stringify(correctAnswerIds.sort()) ===
          JSON.stringify(userAnswerIds.sort());
      }

      if (isCorrect) {
        correct++;
      } else {
        incorrect++;
      }
    }

    setCorrectAnswers(correct);
    setIncorrectAnswers(incorrect);

    const sendResultsByEmail = async () => {
      if (resultsSent) {
        return;
      }

      setIsLoading(true);
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {};
      const email = workplaceEmail[user.workplace];

      let results = `<p style="font-size: 15px; margin-top: 15px; margin-bottom: 0; padding-left: 20px;"><b>${user.name} (${user.workplace})</b></br>`;
      results += `Правильные ответы: <b>${correct}</b></br>`;
      results += `Неправильные ответы: <b>${incorrect}</b></p>`;

      const allQuestionIds = questionsData.map((q) => q.questionId.toString());
      const half = Math.ceil(allQuestionIds.length / 2);
      const firstHalfIds = allQuestionIds.slice(0, half);
      const secondHalfIds = allQuestionIds.slice(half);

      results +=
        '<table style="width: 100%; font-size: 13px; margin-top: 0; margin-bottom: 0; padding-left: 20px; padding-right: 10px;"><tr><td style="vertical-align: top;">';

      for (let questionId of firstHalfIds) {
        results += generateQuestionHTML(questionId);
      }

      results += '</td><td style="vertical-align: top;">';

      for (let questionId of secondHalfIds) {
        results += generateQuestionHTML(questionId);
      }

      results += "</td></tr></table>";

      try {
        const response = await axios.post("/send-mail", {
          email,
          results,
          userName: user.name,
        });
        console.log(response.data);
        setIsLoading(false);
        setResultsSent(true);
      } catch (error) {
        console.error("Error:", error);
        setIsLoading(false);
      }
    };
    console.log("Sending email...");
    sendResultsByEmail();

    function generateQuestionHTML(questionId) {
      const question = questionsData.find(
        (q) => q.questionId === parseInt(questionId)
      );
      if (!question) return "";

      const correctAnswerIds = question.answers
        .filter((answer) => answer.isCorrect)
        .map((answer) => answer.answerId);

      let userAnswerIds = userAnswersData[questionId];
      // Treat unanswered questions as incorrect
      userAnswerIds = userAnswerIds ? userAnswerIds : [];

      const isCorrect =
        JSON.stringify(correctAnswerIds.sort()) ===
        JSON.stringify(userAnswerIds.sort());

      let questionStyle =
        "font-size: 13px; margin-top: 5px; margin-bottom: 0; padding-right: 10px;";
      if (!isCorrect) {
        questionStyle += " color: red;";
      } else {
        questionStyle += " color: green;";
      }

      let questionHTML = `<p style="${questionStyle}"><b>${question.questionId}. ${question.questionText}</b></p>`;

      for (let answer of question.answers) {
        const userSelected = userAnswerIds.includes(answer.answerId);
        const isCorrect = answer.isCorrect;

        if (userSelected) {
          if (isCorrect) {
            questionHTML += "✔️ ";
          } else {
            questionHTML += "❌ ";
          }
        } else {
          // Differentiate between unchecked and unanswered
          questionHTML += userAnswerIds.length > 0 ? "➖ " : "🔲 ";
        }
        questionHTML += `${answer.answerText}<br>`;
      }

      return questionHTML;
    }
  }, [questionsData, userAnswersData, resultsSent]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${logo})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "385px",
              width: "385px",
            }}
          ></Box>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Typography component="h2" variant="h6">
              {t("your-results")}
            </Typography>
            <Typography component="p">
              {t("correct-answers")} {correctAnswers}
            </Typography>
            <Typography component="p">
              {t("wrong-answers")} {incorrectAnswers}
            </Typography>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
