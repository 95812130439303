import React, { useContext } from "react";
import AppContext from "../AppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Header from "./Header";

const defaultTheme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

export default function UserInfo() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user, setUser } = useContext(AppContext); // Из контекста

  // Новые состояния ошибок
  const [nameError, setNameError] = React.useState(false);
  const [workplaceError, setWorkplaceError] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Проверка, что поля заполнены
    if (!user.name) {
      setNameError(true);
    }
    if (!user.workplace) {
      setWorkplaceError(true);
    }
    if (!user.name || !user.workplace) {
      return;
    }

    // Сохранение данных в контекст при отправке формы
    setUser({
      name: user.name,
      workplace: user.workplace,
    });

    // Переход на страницу тестирования
    navigate("/start-test");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Header />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
          {t("enter-your-data")}
        </Typography>
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            noValidate
            sx={{ mt: 5 }}
            onSubmit={handleSubmit}
          >
            <TextField
              required
              fullWidth
              id="full-name"
              label={t("full-name")}
              name="full-name"
              autoFocus
              value={user.name}
              error={nameError}
              helperText={nameError ? t("name-error") : ""}
              onChange={(event) => {
                setUser({ ...user, name: event.target.value });
                setNameError(false); // Сброс ошибки при вводе
              }}
            />
            <FormControl
              required
              fullWidth
              sx={{ mt: 2 }}
              error={workplaceError}
            >
              <InputLabel id="demo-simple-select-label">
                {t("working-place")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Working Place"
                value={user.workplace}
                onChange={(event) => {
                  setUser({ ...user, workplace: event.target.value });
                  setWorkplaceError(false); // Сброс ошибки при выборе
                }}
              >
                <MenuItem value="">{t("select-workplace")}</MenuItem>{" "}
                <MenuItem value={"CENTRAL OFFICE"}>
                  {t("central-office")}
                </MenuItem>
                <MenuItem value={"NOVOKUZNETSK EVRAZ"}>
                  {t("novokuznetsk-evraz")}
                </MenuItem>
                <MenuItem value={"VYKSA"}>{t("vyksa")}</MenuItem>
                <MenuItem value={"DZERJINSK"}>{t("dzerjinsk")}</MenuItem>
                <MenuItem value={"CHEREPOVETS"}>{t("cherepovets")}</MenuItem>
                <MenuItem value={"ORSK"}>{t("orsk")}</MenuItem>
              </Select>
              {workplaceError && (
                <FormHelperText>{t("workplace-error")}</FormHelperText>
              )}
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("start-test")}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
