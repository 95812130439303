import React, { useContext } from "react";
import AppContext from "../AppContext";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import logo from "../images/hse-logo.png";

const defaultTheme = createTheme();

export default function LanguageSelector() {
  const { setSelectedLanguage } = useContext(AppContext);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang); // save the language to the context
    navigate("/userinfo");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${logo})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "385px",
              width: "385px",
            }}
          ></Box>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth={true}
                  onClick={() => changeLanguage("ru")}
                >
                  Русский
                </Button>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth={true}
                  onClick={() => changeLanguage("tr")}
                >
                  Türkçe
                </Button>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth={true}
                  onClick={() => changeLanguage("en")}
                >
                  English
                </Button>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth={true}
                  onClick={() => changeLanguage("kg")}
                >
                  Кыргызча
                </Button>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth={true}
                  onClick={() => changeLanguage("uz")}
                >
                  O‘zbekcha
                </Button>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth={true}
                  onClick={() => changeLanguage("tj")}
                >
                  Тоҷикӣ
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
