import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translations
import enTranslations from "./locales/en.json";
import ruTranslations from "./locales/ru.json";
import trTranslations from "./locales/tr.json";
import kgTranslations from "./locales/kg.json";
import uzTranslations from "./locales/uz.json";
import tjTranslations from "./locales/tj.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    ru: {
      translation: ruTranslations,
    },
    tr: {
      translation: trTranslations,
    },
    kg: {
      translation: kgTranslations,
    },
    uz: {
      translation: uzTranslations,
    },
    tj: {
      translation: tjTranslations,
    },
  },
  lng: localStorage.getItem("selectedLanguage") || "en", // Изменение здесь
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
