import React, { useContext } from "react";
import AppContext from "../AppContext";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

import logo from "../images/stl-logo.png";

// Импорт флагов
import enFlag from "../images/flags/en.svg";
import ruFlag from "../images/flags/ru.svg";
import trFlag from "../images/flags/tr.svg";
import kgFlag from "../images/flags/kg.svg";
import uzFlag from "../images/flags/uz.svg";
import tjFlag from "../images/flags/tj.svg";

const settings = [
  { lang: "ru", label: "Русский", flag: ruFlag },
  { lang: "tr", label: "Türkçe", flag: trFlag },
  { lang: "en", label: "English", flag: enFlag },
  { lang: "kg", label: "Кыргызча", flag: kgFlag },
  { lang: "uz", label: "O‘zbekcha", flag: uzFlag },
  { lang: "tj", label: "Тоҷикӣ", flag: tjFlag },
];

function Header() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { selectedLanguage, setSelectedLanguage } = useContext(AppContext); // Из контекста
  const { i18n, t } = useTranslation();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang); // Сохраняем выбранный язык в контекст
    handleCloseUserMenu();
  };

  const currentFlag = settings.find(
    (setting) => setting.lang === selectedLanguage
  )?.flag;

  return (
    <AppBar position="static" sx={{ backgroundColor: "white" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={logo} alt="logo" />
          <Box sx={{ flexGrow: 1, textAlign: "right" }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <img
                  src={currentFlag}
                  alt="flag"
                  style={{ marginRight: "10px", width: "25px", height: "auto" }}
                />
                <Typography color="black" component="div">
                  {t("current-language")}
                </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.lang}
                  onClick={() => changeLanguage(setting.lang)}
                >
                  <img
                    src={setting.flag}
                    alt="flag"
                    style={{
                      marginRight: "10px",
                      width: "25px",
                      height: "auto",
                    }}
                  />
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
