import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../AppContext";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Header from "./Header";

const defaultTheme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

export default function StartQuiz() {
  const [activeStep, setActiveStep] = useState(0);
  const { user, userAnswers, updateUserAnswers, selectedLanguage } =
    useContext(AppContext);

  const workplaceTranslationKeys = {
    "GUBAKHA AKM_2": "gubakha-akm2",
    "NOVOKUZNETSK EVRAZ": "novokuznetsk-evraz",
    VYKSA: "vyksa",
    DZERJINSK: "dzerjinsk",
    CHEREPOVETS: "cherepovets",
    ORSK: "orsk",
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  // обработчик для изменения ответов
  const handleAnswerChange = (questionId, answerId, isChecked) => {
    let updatedAnswers;

    if (isChecked) {
      updatedAnswers = {
        ...userAnswers,
        [questionId]: [...(userAnswers[questionId] || []), answerId],
      };
    } else {
      updatedAnswers = {
        ...userAnswers,
        [questionId]: (userAnswers[questionId] || []).filter(
          (id) => id !== answerId
        ),
      };
    }

    updateUserAnswers(updatedAnswers);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // при завершении теста сохраните результаты теста в контекст
  const handleFinish = () => {
    updateUserAnswers(userAnswers); // вместо updateTestResults
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const [questions, setQuestions] = useState([]);

  // Declare a new state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`./questions/questions_${selectedLanguage}.json`)
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data);
        // Set loading to false after the data has been fetched
        setLoading(false);
      });
  }, [selectedLanguage]);

  useEffect(() => {
    // Check if loading is false before navigating
    if (!loading && activeStep === questions.length) {
      navigate("/end-test");
    }
  }, [activeStep, questions.length, navigate, loading]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Header />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography component="h1" variant="h6" sx={{ mt: 3, mb: 1 }}>
          {user.name}
        </Typography>
        <Typography component="h2" variant="h6" sx={{ mt: 1, mb: 3 }}>
          {t(workplaceTranslationKeys[user.workplace]) || user.workplace}
          <Link href="/userinfo" variant="body2" sx={{ ml: 2 }}>
            {t("change-details")}
          </Link>
        </Typography>
        <Box sx={{ maxWidth: 400 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {questions.map((question, index) => (
              <Step key={question.questionId}>
                <StepLabel
                  onClick={() => setActiveStep(index)}
                  optional={
                    index === questions.length - 1 ? (
                      <Typography variant="caption">
                        {t("last-step")}
                      </Typography>
                    ) : null
                  }
                >
                  {question.questionText}
                </StepLabel>
                <StepContent>
                  <FormGroup>
                    {question.answers.map((answer) => (
                      <FormControlLabel
                        sx={{ pt: 1, pb: 1 }}
                        key={answer.answerId}
                        control={
                          <Checkbox
                            sx={{ pt: 0, pb: 0, pr: 1, pl: 1 }}
                            checked={
                              userAnswers[question.questionId]
                                ? userAnswers[question.questionId].includes(
                                    answer.answerId
                                  )
                                : false
                            }
                            onChange={(event) =>
                              handleAnswerChange(
                                question.questionId,
                                answer.answerId,
                                event.target.checked
                              )
                            }
                          />
                        }
                        label={answer.answerText}
                      />
                    ))}
                  </FormGroup>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={
                          activeStep === questions.length - 1
                            ? handleFinish
                            : handleNext
                        }
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {activeStep === questions.length - 1
                          ? t("finish-btn")
                          : t("continue-btn")}
                      </Button>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("back-btn")}
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
