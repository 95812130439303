import React, { createContext, useState } from "react";

const AppContext = createContext();

export default AppContext;

export const AppProvider = ({ children }) => {
  // Попытаемся получить данные из localStorage при инициализации состояния
  const savedUser = localStorage.getItem("user");
  const savedTestResults = localStorage.getItem("testResults");
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const savedUserAnswers = localStorage.getItem("userAnswers");

  const [user, setUser] = useState(
    savedUser ? JSON.parse(savedUser) : { workplace: "", name: "" }
  );
  const [testResults, setTestResults] = useState(
    savedTestResults ? JSON.parse(savedTestResults) : null
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    savedLanguage || "en"
  );
  const [userAnswers, setUserAnswers] = useState(
    savedUserAnswers ? JSON.parse(savedUserAnswers) : {}
  );

  // Обновляем setUser, setTestResults и setSelectedLanguage, чтобы они также обновляли localStorage
  const updateUser = (newUser) => {
    setUser(newUser);
    localStorage.setItem("user", JSON.stringify(newUser));
  };

  const updateTestResults = (newTestResults) => {
    setTestResults(newTestResults);
    localStorage.setItem("testResults", JSON.stringify(newTestResults));
  };

  const updateSelectedLanguage = (newLanguage) => {
    setSelectedLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  const updateUserAnswers = (newUserAnswers) => {
    setUserAnswers(newUserAnswers);
    localStorage.setItem("userAnswers", JSON.stringify(newUserAnswers));
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser: updateUser,
        testResults,
        setTestResults: updateTestResults,
        selectedLanguage,
        setSelectedLanguage: updateSelectedLanguage,
        userAnswers,
        updateUserAnswers,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
